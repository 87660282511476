import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyCz5XR92p1W1sL-BVrZD18cIVM0CoIOnlU",
  authDomain: "portfolio-6b427.firebaseapp.com",
  databaseURL: "https://portfolio-6b427.firebaseio.com",
  projectId: "portfolio-6b427",
  storageBucket: "portfolio-6b427.appspot.com",
  messagingSenderId: "36334864574",
  appId: "1:36334864574:web:bec68e620fa52592be36ef",
  measurementId: "G-NZ7GSXQTDE"
};

export const myFirebase = firebase.initializeApp(firebaseConfig);
export const storageRef = myFirebase.storage().ref();

const baseDb = myFirebase.firestore();
export const db = baseDb;
